import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import axios from "../../lib/axios";
import { useAuth } from "../../context/LoginContext";
import useFetch from "../../hook/useFetch";
import Login from "../../components/intro/Login";
import SwiperSlider from "../../components/intro/Swiper";
import HeaderNews from "../../components/intro/HeaderNews";
import FooterNews from "../../components/intro/FooterNews";
import { detect } from "detect-browser";
import caution from "../../assets/img/mobile/caution.png"
import pc_img from "../../assets/img/mobile/pc.png"
import apple from "../../assets/img/mobile/apple.png"
import google from "../../assets/img/mobile/google.png"
import logo from "../../assets/img/mobile/logo.png"
import "./login.sass";
import { checkValidObj } from "../../lib/common_func";
import Swal from "sweetalert2";
function Intro() {
  const { language } = useContext(LanguageContext);
  const { user, handleLogin, handleDemoLogin } = useAuth();
  const { data, isLoading } = useFetch(
    `/web/main/notice`
  );
  const [notice, setNotice] = useState([]);
  const [userInfo, setUserInfo] = useState({
    companyCode: "",
    userId: "",
    password: "",
    ip: "",
    autoChk: false,
  });
  const [loginModal, setLoginModal] = useState(false);

  const navi = useNavigate();
  const browser = detect();

  const { companyCode, userId, password, autoChk } = userInfo;

  const handleChangeLoginInfo = ({ target: { name, value, checked } }) => {
    if (name === "autoChk") {
      setUserInfo((prev) => ({ ...prev, [name]: checked }));
    } else {
      setUserInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  function closeLoginModal() {
    setLoginModal(false);
  }
  const handleWebLogin = (e) => {
    e.preventDefault();
    if (!companyCode) {
      language === "KOR"
        ? alert("회사코드를 입력해주세요")
        : alert("Please Check CompanyCode Input");
      return false;
    }
    if (!userId) {
      language === "KOR"
        ? alert("아이디를 입력해주세요")
        : alert("Please Check ID Input");
      return false;
    }
    if (!password) {
      language === "KOR"
        ? alert("비밀번호를 입력해주세요")
        : alert("Please Check Password Input");
      return false;
    }
    if (companyCode && userId && password) {
      if(!checkValidObj(userInfo)) {
        Swal.fire({
          title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists')
        })
        return;
      }
      handleLogin(userInfo);
    }
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(" ");
    if (cookies.length) {
      let n, v;
      for (let i = 0; i < cookies.length; i++) {
        [n, v] = cookies[i].split("=");
        if (n === name) {
          return decodeURIComponent(v).replace(";", "");
        }
      }
    }
    return "";
  };
  useEffect(() => {
    
    console.log('os : ', browser.os);
    // if(browser.os === 'Android OS' || browser.os === 'android') {
    //   navi("/https://play.google.com/store/apps/details?id=com.iotplex.fourguard&hl=ko-KR");
    // }
    // if(browser.os === 'iOS') {
    //   navi("/https://apps.apple.com/kr/app/k-lbs-%EA%B5%AD%EB%82%B4-%ED%86%B5%ED%95%A9-%EC%9C%84%EC%B9%98%EA%B4%80%EC%A0%9C%EC%84%9C%EB%B9%84%EC%8A%A4/id6450168549");
    // }
  },[])
  useEffect(() => {
    if (user) {
      if(user.auth.J000 === 'Y') {
        navi("/Adm/o-table");
      } else if(user.auth.I000 === 'Y') {
        navi("/Order");
      } else if(user.auth.A000 === 'Y') {
        navi("/Map");
      } else if (user.auth.C000 === 'Y') {
        navi("/Vlog")
      }
    }
  }, [user]);

  useEffect(() => {
    if (getCookie("id_autoChk") === "Y") {
      setUserInfo((prev) => ({
        ...prev,
        companyCode: getCookie("cd"),
        userId: getCookie("id"),
        autoChk: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      setNotice(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    axios
      // .get("https://jsonip.com")
      .get("https://api.ipify.org?format=json")
      .then((res) => {
        setUserInfo((prev) => ({ ...prev, ip: res.data.ip }));
      })
      .catch((err) => {
        console.log("get ip error : ", err);
      });
  }, [userInfo.ip]);

  return (
    <>
    {(browser.os !== 'android' && browser.os !== 'Android OS' && browser.os !== 'iOS') ? 
     (
      <>
      <SwiperSlider language={language} />
      <div className="intro-wrapper-news">
        <HeaderNews language={language} notice={notice} />

        <div className="login-modal-wrap">
          <div className="login-modal-btn" onClick={() => setLoginModal(true)}>
            <img src="../../assets/img/login/id.svg" alt="" />
            <div className="login-title">
              <img src="../../assets/img/login/login_icon.svg" alt="" />
              <span>로그인</span>
            </div>
          </div>
          <div className="login-demo" onClick={handleDemoLogin}>
            데모 체험
          </div>
            <button
              className="gps-demo-submit"
              onClick={() => {window.open('https://docs.google.com/forms/d/e/1FAIpQLSd-jKdiaEQAh89toV_4t0MkhzbSi7ID3wPBnejwDXz5sVEhjQ/viewform?usp=sf_link')}}
            >
              <img className="ri-verified-badge-fill" src="/assets/img/common/icon-link.svg"/>
              <p className="gps-demo-txt">
                <p>GPS 7일</p>
                <p>무료 체험 신청</p>
              </p>
            </button>
        </div>
        {loginModal ? (
          <Login
            recent={notice}
            language={language}
            changeFun={handleChangeLoginInfo}
            userInfo={userInfo}
            submitFun={handleWebLogin}
            close={closeLoginModal}
          />        
        ) : (
          ""
        )}

        <FooterNews language={language} />
      </div>
      </>
      ) :
      (
        <div className="mobile-wrap">                    
          <span className="top-wrap">
          {/* <img src={caution} className="img-caution"/>           */}
          </span>
          <div className="mobile-content">
            <p className="content-font">
              모바일 환경에서는
            </p>          
            <p className="content-font">
              앱을 통해 접속해주세요
            </p>
            <img src={pc_img} className="img-pc" />
          </div>
          
          <div className="mobile-store-content">
            <p>KLBS 위치관제 사이트는</p>
            <p>PC 환경에 최적화되어 있습니다.</p>
          </div>
          <div className="mobile-store-content">
            <p>PC에서 다시 접속하시거나</p>
            <p>아래의 앱을 다운로드하여 이용 바랍니다.</p>
          </div>
        <div className="mobile-content">
        {browser.os === 'iOS' ?
        (
          
            <a href="https://apps.apple.com/kr/app/k-lbs-%EA%B5%AD%EB%82%B4-%ED%86%B5%ED%95%A9-%EC%9C%84%EC%B9%98%EA%B4%80%EC%A0%9C%EC%84%9C%EB%B9%84%EC%8A%A4/id6450168549">
            <img src={apple} className="img-store"/>
            </a>                                
          
        ) 
        :
        (          
            <a href="https://play.google.com/store/apps/details?id=com.iotplex.fourguard&hl=ko-KR">
            <img src={google} className="img-store"/>
            </a>                                                          
        )
        }
        </div>
        <img src={logo} className="img-logo" />
        </div>
      )
      }
    </>    
  );
}

export default Intro;
