import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import { DeleteFun } from "../../../Atomic/exFunc";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import InputForm from "../../../Atomic/atoms/InputForm";
import Radio from "../../../Atomic/atoms/Radio";
import moment from "moment";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({});

  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    if (rawData.tit === "" || rawData.tit === undefined) {
      Swal.fire({
        title: `${translate("제목을 입력해주세요", "Please enter the title")}`,
      });
      return;
    }
    if (rawData.writer === "" || rawData.writer === undefined) {
      Swal.fire({
        title: `${translate(
          "작성자를 입력해주세요",
          "Please enter writer's name"
        )}`,
      });
      return;
    }
    if (rawData.sort === "" || rawData.sort === undefined) {
      Swal.fire({
        title: `${translate(
          "노출순서를 입력해주세요",
          "Please enter the display order"
        )}`,
      });
      return;
    }
    Swal.fire({
      title: `${translate("등록하시겠습니까?", "Do you want to create?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("확인", "Confirm")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/web/admin/notice/add`, rawData).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: "등록되었습니다.",
            }).then((result) => {
              navi("/Adm/sys-table");
            });
          } else {
            Swal.fire({
              title: "등록에 실패하였습니다.",
            });
          }
        });
      }
    });
  }
  function edit() {
    if (rawData.tit === "") {
      Swal.fire({
        title: `${translate("제목을 입력해주세요", "Please enter the title")}`,
      });
      return;
    }
    if (rawData.write === "") {
      Swal.fire({
        title: `${translate(
          "작성자를 입력해주세요",
          "Please enter writer's name"
        )}`,
      });
      return;
    }
    if (rawData.sort === "") {
      Swal.fire({
        title: `${translate(
          "노출순서를 입력해주세요",
          "Please enter the display order"
        )}`,
      });
      return;
    }
    Swal.fire({
      title: `${translate("수정하시겠습니까?", "Do you want to Edit?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("확인", "Confirm")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/web/admin/notice/edit`, rawData).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: "등록되었습니다.",
            }).then((result) => {
              navi("/Adm/sys-table");
            });
          } else {
            Swal.fire({
              title: "수정에 실패하였습니다.",
            });
          }
        });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/notice/del`, { idx: idx, type: "ADM" }, navi);
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        edit();
      },
      position: "",
    },
  ];
  const buttonDataAdd = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Create",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/notice/detail?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setIsAdd(false);
          setRawData({
            ...res.data,
            type: user.type,
          });
        })
        .catch((err: any) => {
          console.log(`Error Link  ${err.config.url}`);
        });
    } else {
      setIsAdd(true);
      setRawData({
        writer: "관리자",
        act_yn: "Y",
        eme_yn: "N",
        type: user.type,
      });
    }
  }, [idx, user.type]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "시스템 공지사항" : "System Notice"}`}
        menu="/Adm/sys-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                label={translate("제목", "Title")}
                name="tit"
                val={rawData.tit}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <InputForm
                label={translate("작성자", "Writer")}
                name="writer"
                val={rawData.writer}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>

            <tr>
              <th>{translate("긴급여부", "Emergancy")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"eme_yn"}
                    val={"Y"}
                    title={translate("유", "Use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.eme_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"eme_yn"}
                    val={"N"}
                    title={translate("무", "Not use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.eme_yn === "N" ? true : false}
                  />
                </div>
              </td>
              <InputForm
                label={translate("노출순서", "Order")}
                name="sort"
                val={rawData.sort}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
            <tr>
              <th>{translate("사용여부", "Use")}</th>
              <td colSpan={3}>
                <div className="custom-check-group">
                  <Radio
                    name={"act_yn"}
                    val={"Y"}
                    title={translate("유", "Use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.act_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"act_yn"}
                    val={"N"}
                    title={translate("무", "Not use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.act_yn === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{translate("내용", "Content")}</th>
              <td colSpan={3} style={{ height: "200px" }}>
                <div className="custom-input" style={{ height: "100%" }}>
                  <textarea
                    style={{ height: "100%", width: "100%", padding: "1.5rem" }}
                    onChange={inputHandler}
                    value={rawData.ctnt}
                    name="ctnt"
                    placeholder={translate("내용을 입력해주세요", "Content")}
                  ></textarea>
                </div>
              </td>
            </tr>

            {isAdd === false ? (
              <tr>
                <InputForm
                  label={translate("등록자/일시", "Writer/Date")}
                  name="makedate"
                  val={`${rawData.writer || ""} / ${moment.utc(
                    rawData.makedate
                  ).format("YYYY-MM-DD")}`}
                  auto="off"
                  type="text"
                  changeFun={inputHandler}
                  required="required"
                  readonly={true}
                />
                <InputForm
                  label={translate("수정일", "Edit Date")}
                  name="editdate"
                  val={moment.utc(rawData.editdate).format("YYYY-MM-DD")}
                  auto="off"
                  type="text"
                  changeFun={inputHandler}
                  required=""
                  readonly={true}
                />
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        {isAdd ? (
          <CommonControl buttonData={buttonDataAdd} language={language} />
        ) : (
          <CommonControl buttonData={buttonData} language={language} />
        )}
      </Page>
    </>
  );
}
